<!--
 * @Author: nice
 * @Date: 2021-12-03 23:06:12
 * @LastEditTime: 2021-12-03 23:22:57
 * @LastEditors: nice
 * @Description: 协议
-->
<template>
  <div class="protocol-wrap h100">
    <NavBar title="服务协议、隐私政策" background="blue" />
    <div class="protocol-content">
      <b>更新日期：2021 年 9 月 17 日 </b>
      <b>生效日期：2021 年 5 月 17 日</b>
      尊敬的用户，欢迎您使用成都秦川物联网科技股份有限公司的服务。成都秦川物联网科技股
      份有限公司（注册地址/常用办公地址：成都市龙泉驿区经开区南四路 931 号；个人信息保
      护负责人联系方式：XXX.com；以下或称“我们”） 非常重视用户的隐私和个人信息保护，我
      们将按照法律法规要求，采取相应安全保护措施，保护您的个人信息安全。
      成都秦川物联网科技股份有限公司提供的产品和服务（以下或称“秦川”），均适用于本隐私 政策，包括秦川 xx APP，秦川 xx
      微信公众号，秦川 xx 微信小程序，以及其他与秦川相关 的，由成都秦川物联网科技股份有限公司提供的各项产品及服务。
      我们希望通过本《隐私政策》（以下简称“本政策”）向您说明成都秦川物联网科技股份有限
      公司提供的产品及服务如何收集、使用、存储、共享您的个人信息，以及您如何行使您所享 有的相关权利等事宜。
      请您在使用我们的服务前，认真阅读并充分理解本政策的全部内容， 特别是以字体加粗方
      式显著标识的内容。在阅读过程中如您对本政策内容有任何疑问、意见或建议，请通过本政
      策列明的方式联系我们。您使用或在我们更新本政策后继续使用我们的产品或服务，即表示
      您同意本政策（含更新版本）内容，并且同意我们按照本政策收集、使用、存储和共享您的 相关信息。
      需要特别说明的是，本政策不适用于第三方依托我们的产品/服务向您提供的产品/服务，也
      不适用于我们另行独立设置隐私政策的产品或服务。 本隐私政策包含以下内容： 1、我们如何收集您的个人信息
      2、我们如何使用您的个人信息 3、我们如何使用 Cookie 和同类技术 4、我们如何共享、转让、公开披露您的个人信息
      5、我们如何保护您的个人信息 6、我们如何存储存您的个人信息 7、您的权利 8、例外情况
      9、我们如何处理未成年人的个人信息 10、您的个人信息如何在全球范围转移 11、本政策如何更新 12、如何联系我们 一、
      我们如何收集您的个人信息 个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身
      份或者反映特定自然人活动情况的各种信息。
      个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、
      身心健康受到损害或歧视性待遇等的个人信息。 以上个人信息和个人敏感信息包含的内容出自于
      GB/T35273《个人信息安全规范》，分别与 附录 A（资料性附录）-个人信息示例-表 A.1 个人信息举例中的内容和附录
      B（资料性附录） -个人敏感信息判定-表 B.1 个人敏感信息举例。
      为更好地为您提供服务，我们将遵循"合法、正当、必要"原则，在您使用我们的产品及各项
      业务功能（以下简称“服务”）的过程中，收集和使用您的一些信息，我们收集或您提供的信 息将用于：
      保障产品的正常基础运行；实现各项功能和服务；优化、改善产品和服务；保
      障产品、服务以及用户使用安全；遵循法律法规与国家标准的规定。 (一)
      为保证您正常使用我们的产品或服务，我们需要收集、存储和使用下列与您有关的信息： 1、为了保证 xxx
      公众号在用户个人隐私管理方面的合规性和规范性，我们需要获取您的手 机号和当前的版本号信息。
      2、为保证您能正常登录和使用服务，我们需要接收和使用您的个人姓名、电话号码、身份 证号码。
      3、为了保证您正常使用我们的一键登录功能，同时为 了保障网络安全及运营安全，我们需 要 接 收 您 的 终 端 唯 一 识 别
      码 、 SIM 卡 信 息 、 IMSI 信 息 、 IMEI 信 息 、 IDFA(Identifier For Advertising)、终端版本型号、网络
      IP、本机号码、网络类型和手机品 牌信息硬件型号、操作系统版本号信息。如果您连接了他人热点，请不要使用一键登录功能，
      否则您将会侵犯他人隐私信息。 4、为了通过数据分析提升产品性能，我们在使用 ABTest、LINK_ME 神策统计平台过程中，
      需要收集您的 终端属性、操作系统类型，操作系统版本、设备品牌、设备型号、设备类型、
      设备方向、手机屏幕尺寸，定位信息、运营商名称、SDK 类型、SDK 版本、 deviceld ；使 用 QoeR
      时，需要收集您的网络类型、信号格数、经度、纬度、移动速度、重力加速度、手 机型号、手机系统版本、App
      版本、运营商信息。 5、为了能够在您准备安装秦川客户端时正确判断您是安装还是升级新版本，我们需要获取
      您的用户软件列表信息。 6 为帮助我们更好地了解“秦川”及相关服务的运行情况，以便确保运行与提供服务的安 全，
      我们可能记录网络日志信息，以及使用软件及相关服务的频率、崩溃数据、总体安装、 使用情况、性能数据信息。
      7、当您设置或修改个人信息时，我们将收集您的 手机号、头像、昵称、个性签名和邮箱信
      息。8、您在浏览资讯中发布评论时，我们将收集 您发布的信息，并展示您的昵称、头像和发布 内容。
      9、当您使用功能时，我们收集您的 位置信息和网络状态信息，帮助我们不断提升网络质量。
      如您不提供前述信息，可能无法帮助您提升网络质量，但不影响您正常使用“秦川”的其他功
      能。10、为了保障您的账户和个人信息安全，在您进服务密码重置时，我们需要验证您的身份， 为此您可能需向我们提供您的
      手机号和注册该号码时使用的身份证号。如您不提供前述信
      息，可能无法进行如无密码重置，但不影响您正常使用“秦川”的其他功能。
      11、为了保障您的账户和个人信息安全，在您进行详单查询时，需要您向我们提供您的 手
      机号码和服务密码验证您的身份。如您不提供前述信息，可能无法进行详单的查询，但不影 响您正常使用“秦川”的其他功能。
      12、为了保障您的账户和个人信息安全，在您办理实名认证时，我们需要验证您的身份， 为此您可能需向我们提供
      身份证件影印件和手持身份证照片。如您不提供前述信息，可能 无法进行实名认证，但不影响您正常使用“秦川”的其他功能。
      13、在使用我们的服务进行水费充值时，您可以选择与“秦川”合作的第三方支付机构（包括
      支付宝、微信支付、银联支付和和包支付通道，以下称“支付机构”）所提供的支付服务。支
      付功能本身并不收集您的个人信息，但我们需要将您的“秦川”订单号与交易金额信息与这些
      支付机构共享以实现其确认您的支付指令并完成支付。 如您不提供前述信息，可能无法使
      用快捷支付服务，但不影响您使用我们提供的其他服务。
      请您了解，我们收集、使用的上述信息进行了去标识化处理，数据分析仅对应特定的、无法
      直接关联用户身份的编码，无法直接与您的真实身份相关联。
      （二）为您提供本客户端的其他具体服务，本客户端会在提供具体服务前通过弹框方式另行 取得您的授权：
      1、为了保证您在使用秦川客户端时，能够正常加载 H5 页面以及在线升级客户端新版本， 我们需要您授权我们
      接收您手机端的图片、声音、视频等多媒体内容，并获得终端存储功 能。2、当您上传个性化头像时，需要您向我们开放
      相机和照片权限。如您不同意开启这些权限，
      可能无法进行拍照和上传照片进行头像更换，但不影响您使用我们提供的其他服务。
      3、当您使用语音输入服务时，您需要开启手机的 麦克风权限，使用麦克风设备来进行语音
      输入，在使用过程中我们需要收集您的语音内容并进行必要的处理；如您不提供前述信息，
      我们将无法为您提供语音相关服务，但不影响您使用我们提供的其他服务。
      4、当您使用【在线客服】咨询、办理或反馈建议时，我们将收集您发布的信息，您使用 上
      传图片功能时，我们会请求您授权相机和照片权限。您如果拒绝授权提供，可能无法进行
      拍照和上传照片给客服，但不影响您正常使用“秦川”的其他功能。 5、当您使用【音视频客服】功能时，需要您向我们
      开放麦克风和相机的权限，我们仅用于
      保证您与秦川客服人员的正常通话，并不收集您的个人信息。您如果拒绝授权提供，可能无
      法正常使用音频客服功能，但并不影响您正常使用“秦川”的其他功能。 （三）第三方服务收集信息的情形
      在某些特定业务场景下，为了提高业务处理能力、提升响应速度或其他方面考虑，我们可能
      会使用有能力的第三方服务商提供的软件服务工具包（简称 SDK）来为您提供服务 。这些 SDK
      可能会收集和使用您的个人信息，我们会在您授权同意后收集和使用，并由您和第三方 服务商承担由此产生的全部责任。这些
      SDK 包括： （1）腾讯 Bugly SDK：为了监控本客户端崩溃、页面异常问题，为您提供更好的服务，我 们使用了腾讯的 Bugly
      SDK，需要访问您设备的当前状态。 （2）abtest SDK:为了通过数据分析提升您使用产品性能，我们使用了 abtest SDK，需要访
      问您的相机、网络信息、震动、闪光灯权限。 （3）神策 SDK：为了通过数据分析提升产品使用性能，我们使用了神策
      SDK，需要访问您 的网络信息、终端属性及 deviceld 等信息。 (4) MIGu SDK
      ：为了您第一时间获取更多有价值内容，为了更好的为您的终端用服务，我 们使用了 MIGu SDK 能力。 （5）Webtrends SDK
      ：为了推进中国移动原生 App 的规范化、确保中国移动原生 App 用 户个人隐私管理的合规性，进一步加强中国移动 App
      数据化运营管理工作，实现中国移动 App 精准效能评估与运营效果评估，并与门户网站、移动商城、网上营业厅、掌上营业厅
      等多渠道跨屏整合分析与整体化运营，我们使用了 Webtrends SDK 能力。 （6） 推送
      SDK：为了及时向优惠活动、套餐使用阈值等服务通知，我们使用了消息 PUSH
      SDK，如您使用小米、魅族、华为、OPPO、VIVO、苹果手机，我们接入的上述手机 厂商 Push SDK，这些 SDK
      需要获取您的手机终端唯一标识信息，并可能会收集您的手机型 号、系统类型、系统版本、设备屏幕尺寸参数用于实现 APP
      推送服务。如您不希望收到这 些通知或信息，可以在终端设备设置中关闭消息通知。
      上述约定同样适用于以下情形：在服务运营过程中上线的新 SDK 且您同意收集和使用您的 个人信息。
      （四）收集、使用个人信息目的变更的处理
      请您理解，我们向您提供的服务是不断更新和发展的。如您选择使用了前述说明当中尚未涵
      盖的其他服务，基于该服务我们需要收集您的信息的，我们会通过页面提示、交互流程、协
      议约定的方式另行向您说明信息收集的范围与目的，并征得您的同意。我们会按照本政策以
      及相应的用户协议约定使用、存储、对外提供及保护您的信息；如您选择不提供前述信息，
      您可能无法使用某项或某部分服务，但不影响您使用我们提供的其他服务。
      此外，第三方主体可能会通过我们的产品或服务向您提供服务。当您进入第三方主体运营的
      服务页面时，请注意相关服务由第三方主体向您提供。涉及到第三方主体向您收集个人信息
      的，建议您仔细查看第三方主体的隐私政策或协议约定。您有权决定是否向第三方主体提供
      个人信息；如您不向其提供个人信息，可能无法使用其提供的服务。
      另外，根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信 息无需征求您的授权同意：
      •与我们履行法律法规规定的义务相关的； •与国家安全、国防安全直接相关的；
      •与公共安全、公共卫生、重大公共利益直接相关的； •与犯罪侦查、起诉、审判和判决执行等直接相关的；
      •出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的；
      •所收集的个人信息是您自行向社会公众公开的； •根据您要求签订和履行合同所必需的；
      •从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
      •用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故 障。二、 我们如何使用您的个人信息
      （一）为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的
      账户和通信安全，我们会在以下情形中使用您的信息： 1、实现本政策中“我们如何收集您的个人信息”所述目的；
      2、为了保障服务的稳定性与安全性，我们会将您的信息用于身份验证、安全防范、诈骗监
      测、预防或禁止非法活动、降低风险、存档和备份用途；
      3、根据法律法规或监管要求、政府机关的命令、要求、协助函、公检法机关的生效法律文 书等向相关部门进行报告；
      4、邀请您参与我们服务有关的客户调研；
      5、我们会采取脱敏、去标识化的方式对您的信息进行综合统计、分析加工，以便为您提供
      更加准确、个性、流畅及便捷的服务，或帮助我们评估、改善或设计服务及运营活动，或为
      您授权（不考虑授权的形式）的任何第三方提供服务；
      （二）当我们要将信息用于本政策未载明的其他用途时，会按照法律法规及国家标准的要求
      以确认协议、交互流程或具体场景下的文案确认动作的形式再次征得您的同意或确认您向第 三方进行了授权。 三、我们如何使用
      Cookie 和同类技术 Cookie 和同类技术是互联网中的通用常用技术。当您使用“秦川”客户端及相关服务时，我们
      可能会使用相关技术向您的设备发送一个或多个 Cookie 或匿名标识符，以收集和存储您访 问、使用本产品时的信息。我们使用
      Cookie 和同类技术主要为了实现以下功能或服务： （一）保障产品与服务的安全、高效运转
      我们可能会设置认证与保障安全性的 cookie 或匿名标识符，使我们确认您是否安全登录服
      务，或者是否遇到盗用、欺诈等不法行为。这些技术还会帮助我们改进服务效率，提升登录 和响应速度。
      （二）帮助您获得更轻松的访问体验 使用此类技术可以帮助您省去重复您填写个人信息、输入搜索内容的步骤和流程（例如：记
      录搜索历史）。 （三）为您推荐、展示、推送您可能感兴趣的内容或账号 我们可能会利用 Cookie 和同类技术（例如
      Beacon、Proxy 等技术）了解您的偏好和使用习
      惯，进行咨询或数据分析，以改善产品服务及用户体验，并优化您对广告的选择。 我们承诺，我们不会将 Cookie
      用于本隐私政策所述目的之外的任何其他用途。 四、我们如何共享、转让、公开披露您的个人信息 （一）共享
      我们不会与我们以外的任何公司、组织和个 人分享您的个人信息，但您理解并同意以下情况除外：
      1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信
      息。2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信
      息。3、与我们的关联公司共享：您的个人信息可能会与我们的关联公司共享。我们只会共享必
      要的个人信息，且受本政策中所声明目的的约束。关联公司如要改变个人信息的处理目的， 将再次征求您的授权同意。
      我们的关联公司包括我们现在或将来控制、受控制或与其处于共同控制下的任何公司、机 构
      以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管
      理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。
      4、与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙
      伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。
      我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供
      服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。 我们的授权合作伙伴包括以下三大类型：
      1、 商品或技术服务的供应商。我们可能会将您的个人信息共享给支持我们功能的第三方，
      这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务和数据处理。
      我们共享这些信息的目的是可以实现我们产品与/或服务的核心功能，比如我们必须与物流
      服务提供商共享您的订单信息才能安排送货；或者我们需要将您的订单号和订单金额与第三
      方支付机构共享以实现其确认您的支付指令并完成支付等。
      2、第三方商家。我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现
      您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。 3、
      广告、分析服务类的合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息
      （指可以识别您身份的信息，包括姓名、电子邮箱、手机号和位置服务，通过这些信息可以
      联系到您或识別您的身份）与提供广告、分析服务的合作伙伴分享。但我们可能会将使用您
      的信息而形成的用户画像与提供广告、分析服务的合作伙伴共享，以帮助在不识别您个人信
      息的前提下提升广告及服务信息的有效触达率，以便更好的为您提供服务。
      对我们与之共享个人信息的公司、组织和个人，我们会与其签署相应的保密协议，要求他们
      按照我们的要求、本政策以及其他任何相关的保密和安全措施来处理个人信息，且不得将个
      人信息用于相关服务之外的任何其他用途。 （二）转让
      我们不会将您的个人信息转让给任何公司、组织和个人，但您理解并同意以下情况除外：
      1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信
      息；2、在涉及合并、分立、收购、重组或破产清算时，如涉及到个人信息转让，受让您个人信
      息的公司、组织将继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权
      同意。如果受让您个人信息的公司、组织变更个人信息使用目的的，我们会要求其重新取得 您的明示同意。 （三）公开披露
      我们仅会在以下情况下，公开披露您的个人信息： 1、获得您明确同意后；
      2、基于法律法规规定的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况
      下，我们可能会公开披露您的个人信息。我们会承担因公开披露您的个人信息对您的合法权 益造成损害（如有）的相应责任。
      根据相关法律法规及国家标准，以下情形中，我们可能会共享、转让、公开披露个人信息无 需事先征得您的授权同意：
      •与我们履行法律法规规定的义务相关的； •与国家安全、国防安全直接相关的；
      •与公共安全、公共卫生、重大公共利益直接相关的； •与犯罪侦查、起诉、审判和判决执行等直接相关的；
      •出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      •个人信息主体自行向社会公众公开个人信息的；
      •从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。 五、我们如何保护您的个人信息
      （一）我们会严格按照《网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保
      护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第 24 号）、《电话
      用户真实身份信息登记规定》（工业和信息化部令第 25 号）等法律法规的相关要求，建立
      信息安全保障制度，采取技术措施和其他必要措施保护您的个人信息安全。
      （二）为了保障您的信息安全，我们在收集您的信息后，将采取各种合理必要的措施保护您
      的信息。例如，在技术开发环境当中，我们仅使用经过去标识化处理的信息进行统计分析；
      对外提供研究报告时，我们将对报告中所包含的信息进行去标识化处理。我们会将去标识化
      后的信息与可用于恢复识别个人的信息分开存储，确保在针对去标识化信息的后续处理中不 重新识别个人。
      （三）我们承诺我们将使信息安全保护达到业界领先的安全水平。为保障您的信息安全，我
      们致力于使用各种安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非
      授权访问、非授权披露和更改的风险。例如：通过网络安全层软件（SSL）进行加密传输、
      信息加密存储、严格限制数据中心的访问。传输和存储个人敏感信息（含个人生物识别信息）
      时，我们将采用加密、权限控制或去标识化的安全措施。
      （四）我们设立了个人信息保护责任部门，针对个人信息收集、使用、共享和委托处理的全
      过程开展个人信息安全影响评估。同时，我们建立了相关内控制度，对可能接触到您的信息
      的工作人员采取最小够用授权原则；对工作人员处理您的信息的行为进行系统监控，不断对
      工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导。另外，我们的相应网络/
      系统通过了国家网络安全等级保护的测评。
      （五）我们已制定个人信息安全事件应急预案，在不幸发生个人信息安全事件后，我们将按
      照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要
      采取的处置措施、您可自主防范和降低风险的建议及对您的补救措施。我们将及时将事件相 关情况以 APP
      推送通知或发送邮件/短消息的方式告知您，难以逐一告知个人信息主体时，
      我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人
      信息安全事件的处置情况。若您的合法权益受损，我们将承担相应的法律责任。
      （六）请您务必妥善保管好您的登录名及其他身份要素。您在使用我们的服务时，我们会通
      过您的登录名及其他身份要素来识别您的身份。一旦您泄漏了前述信息，您可能会蒙受损失，
      并可能对您产生不利。如您发现登录名及/或其他身份要素可能或已经泄露时，请您立即和
      我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。
      （七）在您终止使用秦川服务后，我们会停止对您的信息的收集和使用，但法律法规或监管
      部门另有规定的除外。当我们的产品或服务发生停止运营的情况时，我们将及时停止收集您
      个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个
      人信息进行删除或匿名化处理，但国家法律法规、规章、规范性文件或政府的政策、命令等
      另有要求或为履行我们的合规义务而保留您的个人信息的除外。 六、我们如何保留、存储您的个人信息 （一）信息存储的地点
      原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。 以下情形除外：
      a)法律法规、政府规章、部门规定或行政、司法指令有明确规定；
      b)将来可能出现跨境传输或存储您的个人信息时，将另行向您告知信息出境的目的、接收方、
      出境数据类型，并获得您的明确授权。 针对以上情形，我们会确保依据法律法规和本政策对您的个人信息提供安全保护。
      （二）存储期限 我们只会在达成本政策所述目的所需的最短期限内保留您的个人信息，在超出保存期限后，
      我们会对您的个人信息进行删除或者匿名化处理，但国家法律法规、规章、规范性文件或政
      府的政策、命令等另有要求或为履行我们的合规义务而保留您的个人信息的除外。 七、您的权利 （一）
      查询和更正、您的个人信息 当您完成秦川服务的账号注登陆并进行合理和必要的身份验证后，您可以通过【我的】查询
      和更正您的头像、昵称、性别、个性签名及邮箱信息。 （二）在以下情形中，您可以向我们提出删除个人信息的请求
      1、如果我们处理个人信息的行为违反法律法规； 2、如果我们收集、使用您的个人信息，却未征得您的同意；
      3、如果我们处理个人信息的行为违反了与您的约定； 4、如果您不再使用我们的产品或服务，或您注销了账号；
      5、如果我们不再为您提供产品或服务。 您可以通过拨打电话
      xxxx，通过客户端在线客服（该功能目前非移动号码暂不可用）等各
      种方式与我们联系提出您想要删除个人信息的请求。当您从我们的客户端中删除信息后，我
      们可能不会立即从备份系统中删除相应的信息，但会在备份更新时及时删除这些信息。
      特别提示您注意，出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法自主修改
      某些初始个人账号信息。如您确有需要修改该类个人账号信息，请根据本隐私政策载明的联 系方式联系我们进行处理。
      （五）撤回已同意的授权 您有权撤回已经同意的授权，撤回同意后，我们将不再处理您相应的个人信息。但您收回同
      意的决定，不会影响此前基于您的授权而开展的个人信息处理。客户端具备撤销已同意授权
      的功能，用户可在手机设置—>权限中进行相关隐私授权的关闭，具体为： Android 手机—>设置—>应用—>权限； IOS
      手机—>设置—>隐私—>权限—>应用； 您注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，
      法律法规另有规定的除外。 响应您的上述请求
      为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您
      验证自己的身份，然后再处理您的请求。
      我们通常免费提供访问、更新、更正、删除服务，但是保留对您的数据访问等请求收取合理 费用的权利。
      我们将在十五天内就您的上述请求做出答复。 响应请求的例外
      当满足以下情况时，我们可能会不响应您基于本条提出的请求，包括但不限于： •与我们履行法律法规规定的义务相关的；
      •与国家安全、国防安全直接相关的； •与公共安全、公共卫生、重大公共利益直接相关的；
      •与犯罪侦查、起诉、审判和判决执行等直接相关的； •有充分证据表明个人信息主体存在主观恶意或滥用权利的；
      •出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      •响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害 的；•涉及商业秘密的。 八、例外情况
      您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们
      可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求： 1、与国家安全、国防安全有关的；
      2、与公共安全、公共卫生、重大公共利益有关的； 3、与犯罪侦查、起诉、审判和判决执行等有关的；
      4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意 的;
      5、所收集的个人信息是个人信息主体自行向社会公众公开的；
      6、从新闻报道、政府信息合法披露的信息中收集的您的个人信息的； 7、根据您的要求签订合同所必需的；
      8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或 服务的故障；
      9、为合法的新闻报道所必需的； 10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述
      的结果时，对结果中所包含的个人信息进行去标识化处理的； 11、 法律法规规定的其他情形。
      九、您的个人信息如何在全球范围转移
      原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。
      如果需要将你的个人信息转移到境外，我们将另行获得您的授权同意，并依据本政策保护您 的个人信息安全。
      十、我们如何处理未成年人的个人信息 （一）我们非常重视对未成年人个人信息的保护。若您是 18
      周岁以下的未成年人，在使用 我们的服务前，应事先取得您家长或法定监护人的同意。我们将根据国家相关法律法规的规
      定保护未成年人的个人信息。 （二）对于经父母或法定监护人同意而收集未成年人个人信息的情況，我们只会在受到法律
      允许、父母或监护人明确同意或者保护未成年人所必要的情況下使用或公开披露此信息。
      （三）如果我们发现自己在未成年人未事先获得可证实的父母或法定监护人同意的情况下使
      用我们的服务而收集了未成年人的个人信息，则会设法尽快删除相关信息。 十一、本政策如何更新
      我们可能会适时对本政策的条款进行修订变更。
      未经您明确同意，我们不会削减您按照本政策所应享有的权利。我们会在本页面上发布对本 政策所做的变更。
      对于重大变更，我们还会提供更为显著的通知。本政策所指的重大变更包括：
      •我们的服务模式发生重大变化，如处理个人信息的目的、处理的个人信息类型、个人信息 的使用方式等；
      •我们在所有权结构、组织架构等方面发生重大变化，如业务调整、破产并购等引起的所有 者变更等；
      •个人信息共享、转让或公开披露的主要对象发生变化； •您参与个人信息处理方面的权利及其行使方式发生重大变化；
      •我们负责处理个人信息安全的责任部门、联络方式或投诉渠道发生变化； •个人信息安全影响评估报告表明存在高风险。
      我们请您仔细阅读变更后的隐私政策内容。 十二、如何联系我们
      如果您对本政策有任何疑问、意见或建议，可以随时通过拨打秦川客服电话、登录秦川客户 端通过在线客服反馈意见或通过邮箱
      xxx.com 的方式与我们联系，我们会积极进行查证、处
      理并尽快答复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合
      法权益，您还可以通过以下外部途径寻求解决方案： （1）向 XXX 人民法院提起民事诉讼；
      （2）向中央网信办举报（举报中心网址为：http://www.12377.cn，举报电话为：12377）。
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
    .protocol-content{
        height: calc(100vh - 46px - 25px);
        overflow-y: auto;
        padding:10px 16px;
        background:#fff;
        color:rgba(0, 0, 0, 0.7);
        box-sizing: border-box;
        b{
            margin-bottom: 5px;
            display: block;
        }
    }
</style>
